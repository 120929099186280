//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import JcRange from '@/components/JcRange.vue'
import Verify from '@/components/verifition/Verify'
import { _logins, _getLoginSmsPin, _rbacPermission } from '@/api/user'

export default {
  components: {
    JcRange,
    Verify,
  },
  data() {
    return {
      swith: false,
      loginCode: false,
      btnDisab: false,
      sizeForm: {
        name: '',
      },
      sendMsgDisabled: false,
      second: 60,
      ruleForm: {
        iphone: '', //手机号
        vfcode: '', //验证码
        pwd: '123456', //密码
        status: false, //控制是否滑动的滑块
        captchaVerification: '', //滑块验证码的回值
      },
      flag: true,
      rules: {},
    }
  },
  mounted() {},
  methods: {
    //滑块验证码成功
    success(res) {
      // console.log(滑块验证码成功);
      this.ruleForm.captchaVerification = res.captchaVerification
      this.getmobilereg()
    },
    useVerify() {
      if (!this.ruleForm.iphone) {
        this.$message('请输入手机号')
      } else {
        this.$refs.verify.show()
      }
    },
    tofws() {
      this.$router.push('/homes/register?type=2')
    },
    toxqf() {
      this.$router.push('/homes/register?type=1')
    },
    // 点击发送验证码的事件
    getmobilereg() {
      if (/^1[123456789]\d{9}$/.test(this.ruleForm.iphone)) {
        // if (!this.ruleForm.status) {
        //   this.$message('请验证滑块')
        // } else {
        this.second = 60
        this.timers()
        _getLoginSmsPin({
          phone: this.ruleForm.iphone,
          captchaVerification: this.ruleForm.captchaVerification,
        }).then((res) => {
          if (res.code == '1') {
            this.loginCode = true
          } else {
            this.loginCode = false
          }
        })
        // }
      } else {
        this.$message('请输入正确的手机号')
      }
    },
    // 定时器
    timers() {
      this.sendMsgDisabled = true
      let auth_timer = setInterval(() => {
        //定时器设置每秒递减
        this.second-- //递减时间
        if (this.second <= 0) {
          this.sendMsgDisabled = false
          clearInterval(auth_timer)
          if (auth_timer) {
            //60s时间结束还原v-show状态并清除定时器
            this.ruleForm.vfcode = ''
            this.loginCode = false
            this.$refs.jcRange.aaa()
            this.onMpanelError()
          }
        }
      }, 1000)
    },
    logins() {
      if (this.loginCode) {
        let from = {
          userName: this.ruleForm.iphone, //手机号
          smsPin: this.ruleForm.vfcode, //验证码
          userPwd: this.ruleForm.pwd, //密码
        }
        this.btnDisab = true
        _logins(from)
          .then((res) => {
            if (res.code == 1) {
              this.btnDisab = false
              this.$cookies.set('token', res.data.token)
              // 存储登录用户类型
              localStorage.setItem(
                'registerType',
                JSON.stringify(res.data.type),
              )
              localStorage.setItem(
                'roleType',
                JSON.stringify(res.data.roleType),
              )
              localStorage.setItem(
                'registername',
                JSON.stringify(res.data.name),
              )
              localStorage.setItem(
                'starlevel',
                JSON.stringify(res.data.starlevel),
              )
              localStorage.setItem('dname', JSON.stringify(res.data.dname))
              if (res.data.status == '1') {
                let data = [
                  {
                    path: '/main/account',
                    active: 'account',
                    icon: 'mengbanzu1_3',
                    name: '账户',
                    children: [
                      {
                        path: '/main/account/eiimprovement',
                        active: '',
                        icon: '',
                        name: '企业信息完善',
                      },
                    ],
                  },
                  {
                    //消息界面
                    path: '/main/news',
                    active: 'news',
                    icon: 'mengbanzu1_4',
                    name: '消息',
                    children: [
                      {
                        path: '/main/news',
                        active: '',
                        icon: '',
                        name: '消息',
                      },
                    ],
                  },
                ]
                localStorage.setItem('returnA', JSON.stringify(data))
                this.$router.push('/homelogin')
              } else {
                this.rbacPermission()
              }
              clearInterval(this.timer)
            } else if (res.code == 2) {
              clearInterval(this.timer)
              this.btnDisab = false
            } else {
              this.btnDisab = false
            }
          })
          .catch(() => {
            this.btnDisab = false
          })
      } else {
        this.$message('请输入手机号，验证码登录')
        this.btnDisab = false
      }
    },
    // 登陆成功获取当前用户的所有按钮权限
    rbacPermission() {
      _rbacPermission({}).then((res) => {
        localStorage.setItem('codeB', JSON.stringify(res.data.ids))
        localStorage.setItem('returnA', JSON.stringify(res.data.return))
        // this.$router.push("/homelogin");
        this.$router.push('/homes/home?login=true')
      })
    },

    //注册
    register() {
      this.$router.push('/homes/registerenter')
    },
    //滑块成功
    onMpanelSuccess() {
      // alert('滑块成功');
      this.ruleForm.status = true
      this.swith = true
    },
    //滑块失败
    onMpanelError() {
      this.swith = false
      this.ruleForm.status = false
    },
    // 重置滑块
    reset() {},
    // this.$router.push("/homelogin");
  },
}
